body {
	margin: 0;
	font-family: 'Inter', 'Roboto' 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-image: url(/assets/images/background.jpg);
	background-size: cover;
	background-attachment: fixed;
}

@media (max-width: 600px) {
	body {
		background-image: none;
	}
}

* {
	box-sizing: border-box;
}

#app {
	height: 100%;
}

.cm-caret span {
	display: none !important;
}

.cookie-modal {
	z-index: 2000 !important;
}

.cm-header u {
	text-decoration: none;
}

.custom-styles {
	--ReactInputVerificationCode-itemWidth: 2.5rem;
	--ReactInputVerificationCode-itemHeight: 3.5rem;
}